$couleur_principale : #012D41;
$couleur_secondaire: #fff;
$blanc: #fff;

/* Général */
$body_background_color : $couleur_principale;
$content_background_color: #fff;
$texte_lien:$couleur_principale;
$texte_lien_hover:$couleur_principale;
$header_background:$couleur_principale;
$slogan_color_texte:#fff;
$titre_color:#666;
$couleur_text: #333;
$border-generales:$couleur_principale;

$bloc-fil-title-color:#fff;
$bloc-fil-title-hover:$couleur_principale;
$bloc-fil-title-background:$couleur_principale;

/* Menu */
$nav_background_color:rgba(255,255,255,.8);
$nav_border_color:$couleur_principale;

$li_background_color:transparent;
$li_background_color_hover:$couleur_principale;
$nav_texte_color:$couleur_principale;
$nav_texte_color_hover:$blanc;

$sous_nav_background-color:#fff;
$nav_sous_texte_color:$couleur_text;
$nav_sous_texte_color_hover:$texte_lien;

/* Produits */
$etiquette_produit_background:$couleur_principale;
$etiquette_produit_texte:#fff;
$fond_produits: $body_background_color;
$produits_text_color: #fff;
$produits_text_color_hover: #fff;

/* Livre d'or */
$message_lo_color:#1BA5B8;

/* Blog / Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:$blanc;
$btn_background_color_hover:#1BA5B8;
$btn_texte_color_hover:$blanc;

/* Footer */
$footer_background_color:$couleur_principale;
$footer_texte_color:#fff;

/* Colonne de gauche */
$colonne_gauche_background:#fff;
$colonne_gauche_link_background:$couleur_principale;
$colonne_gauche_link_color:$blanc;
$colone_gauche_border_color:$border-generales;
$colonne_gauche_sous_link_color:$couleur_text;
$colonne_gauche_sous_link_color_hover:$couleur_principale;

/* Template Panier */
$panier-background-color:transparent;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/832series/declinaison";